import moment from 'moment';
import { thirdPartyApps } from './Constants';
import { toast } from 'react-toastify';

export const getRoper = () => {
  return JSON.parse(localStorage.getItem('roper'));
};

export const getProductTypeFromLeadID = (
  leadTypeIdIdentity,
  leadTypes,
  products,
  productCategories,
) => {
  const productIdIdentity = leadTypes?.find(
    (lead) => lead.leadTypeIdIdentity === leadTypeIdIdentity,
  )?.productIdIdentity;
  const productCategoryIdIdentity = products?.find(
    (product) => product.productIdIdentity === productIdIdentity,
  )?.productCategoryIdIdentity;
  const productCategoryDisplayValue = productCategories?.find(
    (productCategory) =>
      productCategory.productCategoryIdIdentity === productCategoryIdIdentity,
  )?.displayValue;
  return productCategoryDisplayValue;
};

export const getLeadGroupTypeFromLeadID = (
  leadTypeIdIdentity,
  leadTypes,
  leadGroups,
) => {
  const leadGroupTypeIdIdentity = leadTypes?.find(
    (lead) => lead.leadTypeIdIdentity === leadTypeIdIdentity,
  )?.leadGroupTypeIdIdentity;
  const leadGroupDisplayName = leadGroups?.find(
    (leadGroup) =>
      leadGroup.leadGroupTypeIdIdentity === leadGroupTypeIdIdentity,
  )?.displayValue;
  return leadGroupDisplayName;
};

export const getProductTypeFromProductCategoryIdIdentity = (
  productCategories,
  productCategoryIdIdentity,
) => {
  const productCategoryDisplayValue = productCategories?.find(
    (productCategory) =>
      productCategory.productCategoryIdIdentity === productCategoryIdIdentity,
  )?.displayValue;
  return productCategoryDisplayValue;
};

export const getLeadTypeNameFromIdIdentity = (
  leadTypeIdIdentity,
  leadTypes,
) => {
  return leadTypes?.find(
    (lead) => lead.leadTypeIdIdentity === leadTypeIdIdentity,
  )?.displayValue;
};

export const getStateCode = (stateId, statesData) => {
  return statesData.find((state) => state.stateId === stateId)?.stateCode;
};

export const getStateName = (stateId, statesData) => {
  return statesData.find((state) => state.stateId === stateId)?.name;
};

export const getCountyName = (countyId, countyData) => {
  return countyData.find((county) => county.countyId === countyId)?.name;
};

export const formatDate = (string) => {
  const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
  return new Date(string).toLocaleDateString([], options);
};

export const getLeadStatusFromIdIdentity = (
  statusOptions,
  leadStatusIdIdentity,
) => {
  return statusOptions?.find(
    (item) => item?.leadStatusIdIdentity === leadStatusIdIdentity,
  );
};

export const getAvailableBuOptions = () => {
  const roper = getRoper();
  return roper?.businessUnits?.map((item) => ({
    ...item,
    value: item?.businessUnitIdIdentity,
    label: item?.name,
  }));
};

export const getAdCampaignStatus = (adCampaignStatuses, campaignObj) => {
  const adCampaignStatus = adCampaignStatuses?.find(
    (campaign) =>
      campaign?.adCampaignStatusIdIdentity ===
      campaignObj?.adCampaignStatusIdIdentity,
  );
  return adCampaignStatus;
};

export const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const setIsAutomatedOrder = (bool) => {
  localStorage.setItem('isAutomatedOrder', bool);
};

export const monthNames = {
  '01': 'January',
  '02': 'February',
  '03': 'March',
  '04': 'April',
  '05': 'May',
  '06': 'June',
  '07': 'July',
  '08': 'August',
  '09': 'September',
  10: 'October',
  11: 'November',
  12: 'December',
};

export const getNextMonths = (
  monthCount = 12,
  customMonthLabels = Object.keys(monthNames),
) => {
  const currentMonthIndex = new Date().getMonth();
  const currentYear = new Date().getFullYear();

  const monthsWithYears = [];

  for (let i = 0; i < monthCount; i++) {
    const monthIndex = (currentMonthIndex + i) % 12;
    const year = currentYear + Math.floor((currentMonthIndex + i) / 12);
    monthsWithYears.push({
      label: `${new Date(year, monthIndex).toLocaleString('default', {
        month: 'long',
      })} ${year}`,
      value: customMonthLabels[monthIndex],
    });
  }

  return monthsWithYears;
};

export const getIsAutomatedOrder = () =>
  JSON.parse(localStorage.getItem('isAutomatedOrder')) ?? false;

export const setDailyRecurrenceLocalStorage = (value) => {
  localStorage.setItem('dailyRecurrence', JSON.stringify(value));
};

export const getDailyRecurrence = () =>
  JSON.parse(localStorage.getItem('dailyRecurrence')) ?? [];

export const setOrderRecurrenceStartDateLocalStorage = (value) => {
  localStorage.setItem('orderRecurrenceStartDate', JSON.stringify(value));
};

export const getOrderRecurrenceStartDate = () => {
  const todayPlusOneDay = moment(moment(), 'DD-MM-YYYY').add(1, 'days');
  const dateFromLocalStorage = JSON.parse(
    localStorage.getItem('orderRecurrenceStartDate'),
  );
  if (!dateFromLocalStorage) {
    localStorage.setItem(
      'orderRecurrenceStartDate',
      JSON.stringify(todayPlusOneDay),
    );
    return todayPlusOneDay;
  } else {
    return dateFromLocalStorage;
  }
};

export const removeDailyRecurrenceLocalStorage = () => {
  localStorage.removeItem('dailyRecurrence');
};

export const removeOrderRecurrenceStartDateLocalStorage = () => {
  localStorage.removeItem('orderRecurrenceStartDate');
};

export const removeIsAutomateOrderFromLocalStorage = () => {
  localStorage.removeItem('isAutomatedOrder');
};

export const addWeeksToDate = (date, weeks) => {
  const dateCopy = new Date(date);
  dateCopy.setDate(dateCopy.getDate() + 7 * weeks);
  return dateCopy;
};

export const getCurrentTime = () => {
  const date = new Date();
  let hours = date.getHours();
  let minutes = date.getMinutes();

  // Check whether AM or PM
  let newformat = hours >= 12 ? 'PM' : 'AM';

  // Find current hour in AM-PM Format
  hours = hours % 12;

  // To display "0" as "12"
  hours = hours ? hours : 12;

  minutes = minutes < 10 ? '0' + minutes : minutes;
  return hours + ':' + minutes + ' ' + newformat;
};

export const getMinimumQuantityForAutomatedOrder = () => {
  const automatedOrder = JSON.parse(localStorage.getItem('automatedOrder'));
  return automatedOrder?.mimimumQuantity ?? 5;
};

export const getDiscountPercentageForAutomatedOrder = () => {
  const automatedOrder = JSON.parse(localStorage.getItem('automatedOrder'));
  return automatedOrder?.discountPercentage ?? 15;
};

export const getMinimumWeeksForAutomatedOrder = () => {
  const automatedOrder = JSON.parse(localStorage.getItem('automatedOrder'));
  return automatedOrder?.minimumWeeks ?? 4;
};

export const formatDateInLocal = (string) => {
  if (string) {
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    return new Date(string + 'Z').toLocaleDateString([], options);
  } else {
    return '';
  }
};

export const getUserBusinessUnits = () => {
  try {
    const roperData = localStorage.getItem('roper');
    return roperData ? JSON.parse(roperData).userBusinessUnits : null;
  } catch (error) {
    return null;
  }
};

//for reuse this logic to calculate number of selected counties
export const getTotalCountiesLength = (selectedLocations) => {
  let selectedCountiesLength = 0;
  if (selectedLocations) {
    selectedLocations?.forEach((item) => {
      selectedCountiesLength += item?.counties?.length;
    });
  }
  return selectedCountiesLength;
};

export const isShowAutoDialer = () => getRoper()?.isShowAutoDialer;

export const phoneBurnerAppInfo = () =>
  getRoper()?.userAppIntegrations?.find(
    (app) => app.thirdPartyAppIdIdentity === thirdPartyApps.phoneBurner,
  ) ?? {};

export const getFaqSection = () => {
  let id = window.location.hash?.split('#');
  id?.shift();
  return id;
};

export const removeFaqSectionFromUrl = () =>
  window.history.replaceState('', '', window.location.href?.split('#')?.[0]);

export const toastConfig = {
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
};

export const showToastNotification = (notification) => {
  const { severity, message } = notification || {};

  switch (severity) {
    case 90:
      toast.warning(message, toastConfig);
      break;
    case 100:
      toast.error(message, toastConfig);
      break;
    default:
      toast.success(message, toastConfig);
  }
};
export const getAccessToken = () => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      return accessToken;
    }
    console.warn('Access token not found in local storage.');
    return null;
  } catch (error) {
    console.error('Failed to retrieve access token from local storage:', error);
    return null;
  }
};

export const setIsBannerHiddenFromSession = (value) =>
  sessionStorage.setItem('isBannerHidden', value);

export const getIsBannerHiddenFromSession = () =>
  sessionStorage.getItem('isBannerHidden');

export const removeIsBannerHiddenFromSession = () =>
  sessionStorage.removeItem('isBannerHidden');

export const isEmailValid = (value) => {
  const mailformat =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\/d{1,3}\/d{1,3}\/d{1,3}\/d{1,3}\])|(([a-z\-/d]+\.)+[a-zA-Z]{2,}))$/;
  return !(value === '' || !mailformat.test(value));
};
